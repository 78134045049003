@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap');

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* start: Globals */
*,
::before,
::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font: inherit;
}

body {
  font-family: 'Inter', sans-serif;
  color: var(--slate-700);
}
/* end: Globals */

.flex-between {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

@keyframes pulse {
  0% {
    box-shadow: 0px 0px 2px 0px rgba(50, 176, 12, 0.3);
    opacity: 1;
  }
  50% {
    box-shadow: 0px 0px 14px 3px rgba(50, 176, 12, 0.3);
    opacity: 0.7;
  }
  100% {
    box-shadow: 0px 0px 14px 3px rgba(50, 176, 12, 0.3);
    opacity: 1;
  }
}

.pulse-element {
  background-color: transparent;
  border-radius: 50%;
}

.pulse-element.animate {
  animation: pulse 2s infinite;
}

#recording-button {
  width: 100%;
  height: 100%;
  display: block;
  padding: 4px 6px;
}

.green-color {
  color: green !important;
}

.text-center {
  text-align: center;
}

.start-messaging {
  height: 100%;
  display: flex;
  align-items: end;
  justify-content: center;
  padding: 16px;
  color: var(--slate-400);
}

/* start: Chat */
.chat-section {
  box-shadow: inset 0 160px 0 0 var(--emerald-500);
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--slate-100);
}
.chat-container {
  max-width: 1360px;
  width: 100%;
  height: 720px;
  box-shadow: 0 8px 24px -4px rgba(0, 0, 0, 0.1);
  background-color: var(--white);
  position: relative;
}
/* end: Chat */

.welcome-container {
  max-width: 1360px;
  width: 100%;
  height: 720px;
  box-shadow: 0 8px 24px -4px rgba(0, 0, 0, 0.1);
  background-color: var(--emerald-600);
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.get-started {
  display: flex;
  flex-direction: column;
  width: 500px;
  padding: 30px;
  align-items: center;
  justify-content: center;
  background-color: var(--emerald-500);
}
.get-started p {
  font-size: 30pt;
  font-weight: 700;
  display: flex;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 30px;
}
.get-started-p-subtitle {
  color: var(--yellow-500);
}
.get-started-p-title {
  color: var(--slate-800);
}
#login-logo {
  color: var(--yellow-500);
  font-size: 30pt;
}

/* start: Sidebar */
.chat-sidebar {
  width: 64px;
  background-color: var(--slate-100);
  height: 100%;
  display: flex;
  flex-direction: column;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 50;
}
.chat-sidebar-logo {
  font-size: 28px;
  color: var(--emerald-600);
  display: block;
  text-align: center;
  padding: 12px 8px;
  text-decoration: none;
}
.chat-sidebar-menu {
  list-style-type: none;
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 16px 0;
}
.chat-sidebar-menu > * > a {
  display: block;
  text-align: center;
  padding: 12px 8px;
  font-size: 24px;
  text-decoration: none;
  color: var(--slate-400);
  position: relative;
  transition: color 0.15s ease-in-out;
}
.chat-sidebar-menu > * > a:hover {
  color: var(--slate-600);
}
.chat-sidebar-menu > .active > a {
  box-shadow: inset 4px 0 0 0 var(--emerald-500);
  color: var(--emerald-600);
  background-color: var(--emerald-100);
}
.chat-sidebar-menu > * > a::before {
  content: attr(data-title);
  position: absolute;
  top: 50%;
  left: calc(100% - 16px);
  border-radius: 4px;
  transform: translateY(-50%);
  font-size: 13px;
  padding: 6px 12px;
  background-color: rgba(0, 0, 0, 0.6);
  color: var(--white);
  opacity: 0;
  visibility: hidden;
  transition: all 0.15s ease-in-out;
}
.chat-sidebar-menu > * > a:hover::before {
  left: calc(100% - 8px);
  opacity: 1;
  visibility: visible;
}

.content-sidebar-title button {
  font-size: 1.3rem;
  text-decoration: none;
  border: none;
  cursor: pointer;

  padding: 5px 8px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.button-add-chat {
  background: var(--emerald-600);
  transition: color 0.15s ease-in-out;
  padding: 5px 7px !important;
  color: #fff;
}

.button-add-chat:hover {
  color: var(--emerald-500);
}

.close-btn {
  background: transparent;
  font-size: 22px;
}

.chat-sidebar-profile {
  margin-top: auto;
  position: relative;
}
.chat-sidebar-profile-toggle {
  background-color: transparent;
  border: none;
  outline: transparent;
  width: 40px;
  height: 40px;
  margin: 0 auto;
  display: block;
  cursor: pointer;
}
.chat-sidebar-profile-toggle > img {
  object-fit: cover;
  width: 100%;
  height: 100%;
  border-radius: 50%;
}
.chat-sidebar-profile-dropdown {
  position: absolute;
  bottom: 100%;
  left: 16px;
  background-color: var(--white);
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  list-style-type: none;
  border-radius: 4px;
  padding: 4px 0;
  opacity: 0;
  visibility: hidden;
  transform: scale(0.9);
  transform-origin: left bottom;
  transition: all 0.15s ease-in-out;
}
.chat-sidebar-profile.active .chat-sidebar-profile-dropdown {
  opacity: 1;
  visibility: visible;
  transform: scale(1);
}
.chat-sidebar-profile-dropdown a {
  display: flex;
  align-items: center;
  padding: 8px 12px;
  text-decoration: none;
  color: var(--slate-400);
  font-size: 14px;
}
.chat-sidebar-profile-dropdown a:hover {
  background-color: var(--slate-100);
  color: var(--slate-600);
}
.chat-sidebar-profile-dropdown a:active {
  background-color: var(--slate-200);
}
.chat-sidebar-profile-dropdown a i {
  margin-right: 12px;
  font-size: 17px;
}
/* end: Sidebar */

/* start: Content side */
.chat-content {
  padding-left: 64px;
  height: 100%;
  position: relative;
}
.content-sidebar {
  width: 256px;
  background-color: var(--white);
  display: flex;
  flex-direction: column;
  height: 100%;
  position: absolute;
  top: 0;
  left: 64px;
}
.content-sidebar-title {
  font-weight: 600;
  padding: 16px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.content-sidebar-form {
  position: relative;
  padding: 0 16px;
}
.content-sidebar-input {
  padding: 8px 16px;
  background-color: var(--slate-100);
  border: 1px solid var(--slate-300);
  outline: none;
  width: 100%;
  border-radius: 4px;
  padding-right: 32px;
  font-size: 14px;
}
.content-sidebar-input:focus {
  border-color: var(--slate-400);
}
.content-sidebar-submit {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 32px;
  color: var(--slate-400);
  background-color: transparent;
  outline: transparent;
  border: none;
  cursor: pointer;
  transition: color 0.15s ease-in-out;
}
.content-sidebar-submit:hover {
  color: var(--slate-600);
}
.content-messages {
  overflow-y: auto;
  height: 90%;
  margin-top: 16px;
}
.content-messages-list {
  list-style-type: none;
  padding: 8px 0;
  overflow: auto;
  margin-bottom: 55px;
}
.content-messages-list > * > a,
.contact {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: var(--slate-700);
  padding: 6px 16px;
  cursor: pointer;
}
.content-messages-list > * > a:hover {
  background-color: var(--slate-50);
}
.content-messages-list > .active > a {
  background-color: var(--slate-100);
}
.content-message-title,
.content-message-no-users {
  margin-left: 16px;
  margin-right: 16px;
  color: var(--slate-400);
  font-size: 13px;
  font-weight: 500;
  margin-bottom: 2px;
  position: relative;
}
.content-message-title,
.content-message-no-users > * {
  position: relative;
  z-index: 1;
  padding: 0 4px 0 0;
  background-color: var(--white);
}
.content-message-title::before {
  content: '';
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  width: 100%;
  height: 0;
  border-bottom: 1px solid var(--slate-300);
  z-index: 0;
}
.content-message-image {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  object-fit: cover;
  flex-shrink: 0;
  margin-right: 12px;
}
.content-message-info {
  display: grid;
  margin-right: 12px;
  width: 100%;
  overflow: hidden;
}
.content-message-name {
  display: block;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 2px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.content-message-text {
  font-size: 13px;
  color: var(--slate-400);
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.content-message-more {
  text-align: right;
  margin: 0;
  display: flex;
  gap: 5px;
  align-self: baseline;
}
.content-message-unread {
  font-size: 12px;
  font-weight: 500;
  color: var(--white);
  background-color: var(--emerald-500);
  padding: 2px 4px;
  border-radius: 4px;
  align-self: flex-start;
}
.content-message-time {
  font-size: 12px;
  color: var(--slate-400);
  font-weight: 500;
}
/* end: Content side */

/* start: Conversation */
.conversation {
  background-color: var(--slate-100);
  height: 100%;
  padding-left: 256px;
  display: none;
  display: flex;
  flex-direction: column;
}
.conversation.active {
}
.conversation-top {
  padding: 8px 16px;
  background-color: var(--white);
  display: flex;
  align-items: center;
}
.conversation-back {
  background-color: transparent;
  border: none;
  outline: none;
  width: 32px;
  height: 32px;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  cursor: pointer;
  color: var(--slate-400);
  margin-right: 12px;
  display: none;
}
.conversation-back:hover {
  background-color: var(--slate-100);
  border-radius: 50%;
  color: var(--slate-600);
}
.conversation-back:active {
  background-color: var(--slate-200);
}
.conversation-user {
  display: flex;
  align-items: center;
}
.conversation-user-image {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 8px;
}
.conversation-user-name {
  font-weight: 500;
  font-size: 17px;
}
.conversation-user-status {
  color: var(--slate-400);
  font-size: 13px;
}

.conversation-buttons {
  display: flex;
  align-items: center;
  margin-left: auto;
}
.conversation-buttons > * {
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  font-size: 20px;
  background-color: transparent;
  border: none;
  outline: transparent;
  cursor: pointer;
  color: var(--slate-600);
  margin-left: 4px;
}
.conversation-buttons > :hover {
  background-color: var(--slate-100);
  color: var(--slate-700);
}
.conversation-buttons > :active {
  background-color: var(--slate-200);
}

.conversation-main {
  overflow-y: auto;
  overflow-x: hidden;
  height: 100%;
  padding: 16px 4px;
  display: flex;
  flex-direction: column-reverse;
}
.conversation-wrapper {
  list-style-type: none;
}
.conversation-item {
  display: flex;
  align-items: flex-end;
  /* flex-direction: row-reverse; */
  margin-bottom: 16px;
}
.conversation-item.me {
  flex-direction: row-reverse;
}
.conversation-item-side {
  margin-left: 8px;
}
.conversation-item.me .conversation-item-side {
  margin-right: 8px;
}
.conversation-item-image {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  object-fit: cover;
  display: block;
}
.conversation-item-content {
  /* width: 100%; */
}
.conversation-item.me .conversation-item-box {
  margin-left: unset;
}
.conversation-item-text {
  position: relative;
  padding: 12px 16px 8px;
  background-color: var(--emerald-500);
  box-shadow: 0 2px 12px -2px var(--emerald-500);
  color: rgba(255, 255, 255, 0.8);
  font-size: 14px;
  border-radius: 6px;
  line-height: 1.5;
  margin: 0 18px 0 12px;
  word-wrap: break-word;
}
.conversation-item-text.typing {
  display: flex;
  align-items: baseline;
  padding: 6px 14px;
  font-size: 0.85rem;
}
.conversation-item-text::before {
  content: '';
  position: absolute;
  bottom: 6px;
  left: -3px;
  width: 14px;
  height: 14px;
  transform: rotate(45deg);
  background-color: var(--emerald-500);
}

.conversation-item.me .conversation-item-text::before {
  left: auto;
  right: -4px;
  background: #fff !important;
}
.conversation-item.me .conversation-item-text {
  margin-left: 16px;
  margin-right: 12px;
}
.conversation-item.me .conversation-item-text {
  background-color: var(--white);
  box-shadow: 0 2px 12px -2px rgba(0, 0, 0, 0.1);
  color: #333;
}
.conversation-item-time {
  font-size: 10px;
  color: var(--slate-400);
  display: block;
  text-align: right;
  margin-top: 4px;
  line-height: 1;
}
.conversation-item .conversation-item-time {
  color: rgba(255, 255, 255, 0.7);
}
.conversation-item.me .conversation-item-time {
  color: #999;
}
.conversation-item-time-container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 0.5rem;
}
.conversation-item-status {
  font-size: 10px;
  color: var(--slate-400);
  display: block;
  text-align: right;
  margin-top: 4px;
  line-height: 1;
}

.seen {
  color: #4cc956;
}
.conversation-item-dropdown {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  visibility: hidden;
  transition: all 0.15s ease-in-out;
}
.conversation-item.me .conversation-item-dropdown {
  left: unset;
  right: 0;
}
.conversation-item-wrapper:hover .conversation-item-dropdown {
  opacity: 1;
  visibility: visible;
}
.conversation-item-dropdown-toggle {
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  background-color: var(--white);
  outline: transparent;
  border: 1px solid var(--slate-200);
  cursor: pointer;
  transition: all 0.15s ease-in-out;
}
.conversation-item-dropdown-toggle:hover {
  background-color: var(--emerald-500);
  color: var(--white);
  box-shadow: 0 2px 12px -2px var(--emerald-500);
}
.conversation-item-dropdown-toggle:active {
  background-color: var(--emerald-600);
}
.conversation-item-dropdown-list {
  position: absolute;
  top: 100%;
  left: 0;
  background-color: var(--white);
  z-index: 10;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  padding: 4px 0;
  list-style-type: none;
  opacity: 0;
  visibility: hidden;
  transform: scale(0.9);
  transform-origin: left top;
  transition: all 0.15s ease-in-out;
}
.conversation-item.me .conversation-item-dropdown-list {
  left: unset;
  right: 0;
}
.conversation-item-dropdown.active .conversation-item-dropdown-list {
  opacity: 1;
  visibility: visible;
  transform: scale(1);
}
.conversation-item-dropdown-list a {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: var(--slate-400);
  font-size: 13px;
  padding: 6px 12px;
}
.conversation-item-dropdown-list a:hover {
  background-color: var(--slate-100);
  color: var(--slate-600);
}
.conversation-item-dropdown-list a:active {
  background-color: var(--slate-200);
}
.conversation-item-dropdown-list a i {
  font-size: 16px;
  margin-right: 8px;
}
.coversation-divider {
  text-align: center;
  font-size: 13px;
  color: var(--slate-400);
  margin-bottom: 16px;
  position: relative;
}
.coversation-divider::before {
  content: '';
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  width: 100%;
  height: 0;
  border-bottom: 1px solid var(--slate-300);
}
.coversation-divider span {
  display: inline-block;
  padding: 0 8px;
  background-color: var(--slate-100);
  position: relative;
  z-index: 1;
}

.conversation-form {
  padding: 8px 16px;
  background-color: var(--white);
  display: flex;
  align-items: flex-end;
}
.conversation-form-group {
  width: 100%;
  position: relative;
  margin-left: 16px;
  margin-right: 16px;
}
.conversation-form-input {
  background-color: var(--slate-100);
  border: 1px solid var(--slate-300);
  border-radius: 4px;
  outline: transparent;
  padding: 10px 32px 10px 16px;
  font: inherit;
  font-size: 14px;
  resize: none;
  width: 100%;
  display: block;
  line-height: 1.5;
  max-height: calc(20px + ((14px * 2) * 6));
  height: 43px;
}
.conversation-form-input:focus {
  border-color: var(--slate-400);
}
.conversation-form-record {
  position: absolute;
  bottom: 5px;
  right: 15px;
  font-size: 20px;
  color: var(--slate-400);
  background-color: transparent;
  border: none;
  outline: transparent;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 6px;
}
.conversation-form-record:hover {
  color: var(--slate-600);
}
.conversation-form-button {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  border: none;
  background-color: transparent;
  outline: transparent;
  font-size: 20px;
  color: var(--slate-400);
  cursor: pointer;
  flex-shrink: 0;
}
.conversation-form-button:hover {
  background-color: var(--slate-100);
  color: var(--slate-600);
}
.conversation-form-button:active {
  background-color: var(--slate-200);
  color: var(--slate-600);
}
.conversation-form-submit {
  background-color: var(--emerald-500);
  box-shadow: 0 2px 8px -2px var(--emerald-500);
  color: var(--white);
}
.conversation-form-submit:hover {
  background-color: var(--emerald-600);
  color: var(--white);
}
.conversation-form-submit:active {
  background-color: var(--emerald-700);
  color: var(--white);
}
.conversation-default {
  align-items: center;
  justify-content: center;
  padding: 16px;
  padding-left: calc(256px + 16px);
  color: var(--slate-400);
}
.conversation-default i {
  font-size: 32px;
}
.conversation-default p {
  margin-top: 16px;
}
/* end: Conversation */

.badge {
  padding: 3px 5px;
  font-size: 0.8rem;
  background: #2560ad;
  border-radius: 3px;
  color: #fff;
}

/* start: Breakpoints */
@media screen and (max-width: 1600px) {
  .chat-container {
    max-width: unset;
    height: 100vh;
  }
}

@media screen and (max-width: 767px) {
  .chat-sidebar {
    top: unset;
    bottom: 0;
    width: 100%;
    height: 48px;
  }
  .chat-sidebar-logo {
    display: none;
  }
  .chat-sidebar-menu {
    flex-direction: row;
    padding: 0;
  }
  .chat-sidebar-menu > *,
  .chat-sidebar-menu > * > a {
    width: 100%;
    height: 100%;
  }
  .chat-sidebar-menu > * > a {
    padding: 8px;
  }
  .chat-sidebar-menu > .active > a {
    box-shadow: inset 0 4px 0 0 var(--emerald-500);
  }
  .chat-sidebar-profile {
    margin-top: 0;
    display: flex;
    align-items: center;
  }
  .chat-sidebar-profile-toggle {
    width: 32px;
    height: 32px;
  }
  .chat-sidebar-profile-dropdown {
    left: unset;
    right: 16px;
  }

  .conversation,
  .chat-content {
    padding-left: unset;
  }
  .content-sidebar {
    left: unset;
    z-index: 10;
    width: 100%;
  }
  .chat-sidebar-menu > * > a::before {
    left: 50%;
    transform: translateX(-50%);
    bottom: 100%;
    top: unset;
  }
  .chat-sidebar-menu > * > a:hover::before {
    bottom: calc(100% + 8px);
    left: 50%;
  }

  .chat-content {
    position: relative;
    /* height: calc(100% - 48px); */
  }
  .conversation.active {
    position: relative;
    z-index: 20;
  }
  .conversation-back {
    display: flex;
  }
  .conversation-default.active {
    display: none;
    padding: 16px;
  }
}
/* end: Breakpoints */

.red {
  background-color: red;
}
.orange {
  background-color: orange;
}

.hide {
  display: none;
}

.notRec {
  background-color: darkred;
}

.Rec {
  animation-name: pulse;
  animation-duration: 1.5s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

/* The Modal (background) */
.modal-add-chat {
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 100; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
}

/* Modal Content/Box */
.modal-add-chat-content {
  background-color: #fefefe;
  margin: auto;
  width: 100%;
}

/* The Close Button */
.modal-add-chat-content > .close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.modal-add-chat-content > .close:hover,
.modal-add-chat-content > .close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

.chat-button {
  width: 70px;
  height: 70px;
  background-color: #007bff;
  color: #fff;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.fa-comment-o {
  font-size: 24px;
}

.unreaded-messages-counter {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  background: red;
  /* padding: 0px 4px; */
  border-radius: 50%;
  width: 22px;
  height: 22px;
  align-items: center;
  justify-content: center;
  font-size: 0.9rem;
}
